import Pagination from "components/Pagination.js";

export default function TakeSkipPagination({
  items,
  isFetching,
  paging,
  onPageChange,
}) {
  let length = items?.length ?? 0;
  let skip = paging?.skip ?? 0;
  let take = paging?.take ?? 5;
  return (
    <>
      <Pagination
        isFetching={isFetching}
        currentPage={1 + Math.ceil(skip / take)}
        itemsPerPage={take}
        totalPages={Math.ceil(length / take)}
        totalItems={length}
        onPageChange={(x) => onPageChange({...paging, skip: ((x??1)-1)*take})}
        onItemsPerPageChange={(x) => onPageChange({...paging, take: x})}
      />
    </>
  );
}
