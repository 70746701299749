import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from "@heroicons/react/24/outline";
import LabelAndValue from "components/SimpleLabelValue";
import TakeSkipPagination from "components/TakeSkipPagination.js";

function BuildUrl(base_url, pagingData, searchObject) {
  let url = [];
  if(searchObject !== {} && searchObject !== null && searchObject !== undefined && Object.keys(searchObject).length > 0) {
    let filters = [];
    Object.keys(searchObject).forEach(k => filters.push(k + ' ' + searchObject[k].toString()))
    url.push(`$filter=(${filters.join(' or ')})`);
  }
  url.push('$count=true');
  url.push('$orderby=id desc');
  if (pagingData !== null) {
    url.push(`$top=${pagingData.itemsPerPage}`);
    if(pagingData.currentPage > 1) {
      url.push(`$skip=${(pagingData.currentPage-1) * pagingData.itemsPerPage}`);
    }
  }
  return base_url + '?' + url.join('&');
}

function NewOcrModal(onSave) {
  let [data, setData] = useState(false);
  let reset = () => setData({x: 0.5, y: 0.5, weight: 1});

  if(!data)
    return [(<></>), reset];

  let html = (
    <>
      <Dialog open={!!data} onClose={() => setData(false)} className="relative z-50 inset-0">
        <div className="fixed inset-0 flex items-center justify-center p-4 backdrop-blur-sm text-zinc-900 dark:text-zinc-100">
          <Dialog.Panel className="max-w-5xl max-h-lg space-y-4 border p-12 rounded-lg bg-white dark:bg-zinc-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
            <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div>id:<b>{data.id}</b></div>
              <button
                type="button"
                className="relative inline-flex text-red-500 hover:text-zinc-900 dark:hover:text-zinc-400 focus:z-10"
                onClick={() => setData(false)}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <form onSubmit={e => {e.preventDefault(); onSave(data); setData(false);}}>
              <div>
                <LabelAndValue Label="Text" Value={data.text} onUpdate={v => setData({...data, text: v})}/>
                <LabelAndValue Label="X" Value={data.x} onUpdate={v => setData({...data, x: v})}/>
                <LabelAndValue Label="Y" Value={data.y} onUpdate={v => setData({...data, y: v})}/>
                <LabelAndValue Label="Weight" Value={data.weight} onUpdate={v => setData({...data, weight: v})}/>
              </div>
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
              >
                Create
              </button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
  return [html, reset];
}
function EditOcrModal(ocrList, onSave, onDelete) {
  let [isOpen, setId] = useState(false);
  let [data, setData] = useState({});
  useEffect(() => {
    setData({...(ocrList?.find(item => item.id == isOpen) ?? {})});
  }, [ocrList, isOpen]);

  if(!isOpen)
    return [(<></>), setId];

  let html = (
    <>
      <Dialog open={!!isOpen} onClose={() => setId(false)} className="relative z-50 inset-0">
        <div className="fixed inset-0 flex items-center justify-center p-4 backdrop-blur-sm text-zinc-900 dark:text-zinc-100">
          <Dialog.Panel className="max-w-5xl max-h-lg space-y-4 border p-12 rounded-lg bg-white dark:bg-zinc-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
            <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div>id:<b>{data.id}</b></div>
              <button
                type="button"
                className="relative inline-flex text-red-500 hover:text-zinc-900 dark:hover:text-zinc-400 focus:z-10"
                onClick={() => setId(false)}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <form onSubmit={e => {e.preventDefault(); onSave(data); setId(false);}}>
              <div>
                <LabelAndValue Label="Text" Value={data.text} onUpdate={v => setData({...data, text: v})}/>
                <LabelAndValue Label="X" Value={data.x} onUpdate={v => setData({...data, x: v})}/>
                <LabelAndValue Label="Y" Value={data.y} onUpdate={v => setData({...data, y: v})}/>
                <LabelAndValue Label="Weight" Value={data.weight} onUpdate={v => setData({...data, weight: v})}/>
              </div>
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
              >
                Save
              </button>
              <button
                type="button"
                style={{display: (onDelete === undefined ? "none" : "unset")}}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-red-700 hover:bg-zinc-50 dark:hover:bg-red-600 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:mt-0 sm:w-auto"
                onClick={() => {onDelete(isOpen);setId(false);}}
                data-autofocus
              >
                Delete
              </button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
  return [html, setId];
}

const SelectOptions = [
  {text: 'Acord 125, page 1', url: '/acord_docs/125_1.png'},
  {text: 'Acord 125, page 2', url: '/acord_docs/125_2.png'},
  {text: 'Acord 125, page 3', url: '/acord_docs/125_3.png'},
  {text: 'Acord 125, page 4', url: '/acord_docs/125_4.png'},

  {text: 'Acord 126, page 1', url: '/acord_docs/126_1.png'},
  {text: 'Acord 126, page 2', url: '/acord_docs/126_2.png'},
  {text: 'Acord 126, page 3', url: '/acord_docs/126_3.png'},
  {text: 'Acord 126, page 4', url: '/acord_docs/126_4.png'},

  {text: 'Acord 140, page 1', url: '/acord_docs/140_1.png'},
  {text: 'Acord 140, page 2', url: '/acord_docs/140_2.png'},
  {text: 'Acord 140, page 3', url: '/acord_docs/140_3.png'},
];
export default function OCRConfig() {
  const [processedResults, setProcessedResult] = useState([]);
  const [{url, file}, setImageSource] = useState({url: SelectOptions[0].url, file: undefined});
  const [editDragOCR, setEditDragOCR] = useState(false);
  const [draggingId, setDraggingId] = useState(false);

  let [selectId, setSelectId] = useState(SelectOptions[0]);
  let {data: ocr_data, isLoading: ocr_loading, error: ocr_error, refetch} = useQuery({
    queryKey: ["ocr", selectId],
    queryFn: async () => {
      let searchParams = {pageId: " eq '" + selectId.text + "'"};
      let ocr_url = BuildUrl('administration/autoclearance/ocr', null, searchParams);
      return await axios.get(ocr_url).then(d => d?.data ?? []);
    },
    staleTime: 1000*60*3
  });
  let [ocrAddModal, newOcr] = NewOcrModal(onAddState => {
    let pageId = selectId.text;
    axios.post('/administration/autoclearance/ocr', {...onAddState, pageId}).then(() => refetch()).catch(console.error);
  });
  let [ocrEditModal, setOcrEditId] = EditOcrModal(ocr_data, onSaveState => {
    axios.put('/administration/autoclearance/ocr', onSaveState).then(() => refetch()).catch(console.error);
  }, deleteId => {
    axios.delete('/administration/autoclearance/ocr/' + deleteId).then(() => refetch()).catch(console.error);
  });
  let [ocrPaging, setOcrPaging] = useState({take: 5, skip: 0});
  let [processedPaging, setProcessedPaging] = useState({take: 5, skip: 0});
  let {mutate: uploadMutate} = useMutation({
    mutationFn: async () => {
      let input = document.createElement("input");
      input.type = "file";
      input.setAttribute("multiple", false);
      input.setAttribute("accept", "image/*");
      input.onchange = () => {
        if(input.files.length == 0)
          return;
        axios.postForm('/administration/autoclearance/ocr/upload', {file: input.files[0]})
          .then(x => {
            setProcessedResult(x.data ?? []);
            setImageSource({url: undefined, file: input.files[0]});
          })
          .catch(console.error);
      };
      input.click();
    },
  });

  const imgCanvasRef = useRef(null);
  useEffect(() => {
    const canvas = imgCanvasRef.current;
    if (canvas === null || canvas === undefined)
      return;
    const context = canvas.getContext('2d');
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    let img = new Image();
    img.onload = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.imageSmoothingEnabled = false;
      context.canvas.width = img.width;
      context.canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);
    };
    img.width = canvas.width;
    img.height = canvas.height;
    img.src = url ?? URL.createObjectURL(file);
    return () => {img.remove()};
  }, [url, file]);
  const overlayCanvasRef = useRef(null);
  useEffect(() => {
    const canvas = overlayCanvasRef.current;
    if (canvas === null || canvas === undefined)
      return;
    const context = canvas.getContext('2d');
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    let drawCircle = (x, y, color) => {
      context.save();
      context.fillStyle = color ?? 'lightgreen';
      context.stokeStyle = 'black';
      context.lineWidth = 2;
      context.beginPath();
      context.arc(x * canvas.width, y * canvas.height, 5, 2 * Math.PI, false);
      context.fill();
      context.stroke();
      context.closePath();
      context.restore();
    };

    let drawSquare = (x, y, w, h, color, line) => {
      context.save();
      context.lineWidth = 2;
      context.stokeStyle = line ?? 'black';
      context.fillStyle = color ?? 'white';
      context.beginPath();
      context.rect(x, y, w, h);
      context.fill();
      context.stroke();
      context.closePath();
      context.restore();
    };
    let drawToolTip = (x, y, text) => {
      context.save();
      context.lineWidth = 2;
      context.font = "12px serif";
      let measure = context.measureText(text);
      x = x - measure.width / 2
      if(y < 100) {
        y = y + 60;
      }

      context.stokeStyle = 'black';
      context.fillStyle = 'white';
      context.beginPath();
      let yHeight = 25;
      context.rect(x-10, y-25-(yHeight/2), 20+measure.width, yHeight);
      context.fill();
      context.stroke();
      context.closePath();

      context.fillStyle = 'black';

      context.fillText(text, x, y - 20);

      context.restore();
    };
    let getClosestOcr = (mouseX, mouseY) => {
      let ocr_closest = [99999, undefined];
      for(let i in ocr_data) {
        let xdist = mouseX - (ocr_data[i].x * canvas.width);
        let ydist = mouseY - (ocr_data[i].y * canvas.height);
        let mouse_to_dot_distance = Math.sqrt((xdist * xdist) + (ydist * ydist));
        if (ocr_closest[0] > mouse_to_dot_distance) {
          ocr_closest = [mouse_to_dot_distance, ocr_data[i]];
        }
      }
      return ocr_closest;
    };
    let drawAll = (mouseX, mouseY) => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      if(processedResults.length > 0) {
        for(let i in processedResults) {
          let p = processedResults[i];
          drawSquare(p.x * canvas.width, p.y * canvas.height, p.width * canvas.width, p.height * canvas.height, '#5553', '#0002');
        }
      }
      // dots
      for(let i in ocr_data) {
        if(ocr_data[i].id !== draggingId)
          drawCircle(ocr_data[i].x, ocr_data[i].y, 'lightgreen');
      }
      if(!editDragOCR) {
        let ocr_closest = getClosestOcr(mouseX, mouseY);
        if (ocr_closest[0] <= 15) {
          drawToolTip(ocr_closest[1].x * canvas.width, ocr_closest[1].y * canvas.height, ocr_closest[1].text);
        }
        else {
          for(let i in processedResults) {
            let p = processedResults[i];
            let mx = mouseX / canvas.width;
            let my = mouseY / canvas.height;
            if(mx >= p.x && mx <= p.x + p.width && my >= p.y && my <= p.y + p.height) {
              drawToolTip(mouseX, mouseY, p.text);
            }
          }
        }
      }
    };
    let mouseMoveE = e => {
      drawAll(e.offsetX, e.offsetY);
      if (draggingId !== false) {
        drawCircle(e.offsetX / canvas.width, e.offsetY / canvas.height, 'red');
      }
    };
    let mouseClickE = e => {
      if(editDragOCR)
        return;
      let ocr_closest = getClosestOcr(e.offsetX, e.offsetY);
      if (ocr_closest[0] < 15) {
        setOcrEditId(ocr_closest[1].id);
      }
    };
    let mouseUpE = e => {
      if(!editDragOCR || draggingId === false)
        return;
      let ocr_on_drop = ocr_data.find(x => x.id === draggingId);
      if(!ocr_on_drop)
        return;
      ocr_on_drop.x = e.offsetX / canvas.width;
      ocr_on_drop.y = e.offsetY / canvas.height;
      setDraggingId(false);
      axios.put('/administration/autoclearance/ocr', ocr_on_drop).then(() => refetch()).catch(console.error);
    };
    let mouseDownE = e => {
      if(!editDragOCR)
        return;
      let ocr_closest = getClosestOcr(e.offsetX, e.offsetY);
      if (ocr_closest[0] < 15) {
        setDraggingId(ocr_closest[1].id);
      }
      console.log('mouse down', e);
    };
    canvas.addEventListener('mousemove', mouseMoveE);
    canvas.addEventListener('click', mouseClickE);
    canvas.addEventListener('mouseup', mouseUpE);
    canvas.addEventListener('mousedown', mouseDownE);
    drawAll();
    return () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      canvas.removeEventListener('mousemove', mouseMoveE);
      canvas.removeEventListener('click', mouseClickE);
      canvas.removeEventListener('mouseup', mouseUpE);
      canvas.removeEventListener('mousedown', mouseDownE);
    };
  }, [ocr_data, editDragOCR, draggingId, processedResults, setDraggingId, refetch]);

  if(ocr_error)
    return (<><div>error: {ocr_error}</div></>);

  return (
    <>
      <main>
        <header className="relative isolate">
          <div className="px-4 py-10 sm:px-6 lg:px-8">
            <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
                  Autoclearance
                </h2>
                <p className="leading-8 text-zinc-600 dark:text-zinc-300">
                  Do things and stuff with forms :D
                </p>
              </div>
              <div className="flex items-center gap-x-4 sm:gap-x-6">
                <button onClick={() => uploadMutate()} className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                  Upload Document
                </button>
                <button className="rounded-md bg-[#4d7c0f]/90 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4d7c0f]"
                  onClick={() => newOcr()}
                >
                  New OCR Box
                </button>
              </div>
            </div>
          </div>
        </header>

        {ocrEditModal}
        {ocrAddModal}
        <LabelAndValue Label="Move" Value={editDragOCR} onUpdate={v => {setEditDragOCR(v); if(!v){setDraggingId(false)}}} type="bool"/>
        <div className="relative px-4 pb-10 sm:px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div className="sticky top-4 lg:col-start-3 lg:row-end-1 space-y-8">
              <div>
                <label
                  htmlFor="location"
                  className="block text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100"
                >
                  OCR Config
                </label>
                <select
                  value={selectId.text}
                  onChange={e => {setSelectId(SelectOptions.find(s => s.text == e.target.value)); setImageSource({url:SelectOptions.find(s => s.text == e.target.value).url});}}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-zinc-900 dark:text-zinc-100 dark:bg-zinc-900 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800 sm:text-sm sm:leading-6"
                >
                  {SelectOptions.map(option => 
                    (<option key={option.text}>{option.text}</option>)
                  )}
                </select>
              </div>

              <div className="rounded-lg bg-zinc-50 dark:bg-zinc-900 shadow ring-1 ring-zinc-900/5 dark:ring-zinc-800 px-4">
                <div className="flow-root">
                  <div className="overflow-x-auto">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-4">
                      {ocr_loading && <div>loading</div>}
                      {!ocr_loading &&
                        <>
                          <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-700">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0"
                                >
                                  Text
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                              {ocr_data.slice(ocrPaging.skip, ocrPaging.take + ocrPaging.skip).map((data) => (
                                <tr key={data.id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                                    {data.text}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <TakeSkipPagination
                            paging={ocrPaging}
                            onPageChange={setOcrPaging}
                            items={ocr_data}
                          ></TakeSkipPagination>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>

              { processedResults.length > 0 &&
                <>
                  <p className="block text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                    Processed Result
                  </p>

                  <div className="rounded-lg bg-zinc-50 dark:bg-zinc-900 shadow ring-1 ring-zinc-900/5 dark:ring-zinc-800 px-4 py-8">
                    <div className="flow-root">
                      <div className="overflow-x-auto">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-700">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0"
                                >
                                  <span className="cursor-pointer">Text</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                              {processedResults.slice(processedPaging.skip, processedPaging.take + processedPaging.skip).map((data, i) => (
                                <tr key={i}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                                    {data.text}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                            <TakeSkipPagination
                              paging={processedPaging}
                              onPageChange={setProcessedPaging}
                              items={processedResults}
                            ></TakeSkipPagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>

            <div className="shadow-sm ring-1 ring-zinc-900/5 dark:ring-zinc-800 sm:mx-0 sm:rounded-lg lg:col-span-2 lg:row-span-2 lg:row-end-2">
              <div style={{position: 'relative'}}>
                <canvas className="w-full object-cover" style={{aspectRatio: 0.68, position: 'absolute', left: 0, top: 0, zIndex: 0}} ref={imgCanvasRef} />
                <canvas className="w-full object-cover" style={{aspectRatio: 0.68, position: 'absolute', left: 0, top: 0, zIndex: 0}} ref={overlayCanvasRef} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
