import './FormInput.scss';

export default function LabelAndValue({Label: label, Value: value, Disabled, onUpdate, type}) {
  const isCheckbox = ['bool', 'boolean', 'check', 'checkbox'].indexOf(type ?? 0) !== -1;
  if(isCheckbox)
    value = !!value;
  //useEffect(() => {onUpdate(value);}, [onUpdate, value ?? ""]);

  if(isCheckbox) {
    return (
      <label>
        <input
          type='checkbox'
          disabled={Disabled}
          defaultChecked={value}
          onChange={e => onUpdate(e.target.checked)}
        />
        {label}
      </label>
    );
  }
  return (
    <label>
      {label}
      <input
        type={ type ?? "text"}
        inputMode={ type === "number" ? "numeric" : ""}
        disabled={Disabled}
        defaultValue={value}
        onChange={e => onUpdate(e.target.value)}
      />
    </label>
  );
}
